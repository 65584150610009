import './bootstrap';
import '../css/app.css';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';

createInertiaApp({
    title: (title) => `${title}`,
    resolve: name => {
        const pages = import.meta.glob('./Pages/**/*.jsx')
        return pages[`./Pages/${name}.jsx`]()
    },
    // resolve: name => require(`./Pages/${name}`),
    setup({ el, App, props}) {
        createRoot(el).render(<App {...props} />)
    },
})
